import { version } from "./version";

export const environment = {
  production: true,
  version: version,
  environmentName: "prod_us",
  rollbarToken: "a7968ea6d6014d56b985476baa065477",
  visitoRHub: "https://visitor-us.vee24.com",
  engageHub: "https://engage-us.vee24.com",
  customerEngageRHub: "engage-us.vee24.com",
  authApiRoot: "https://visitor-us.vee24.com/auth",
  configApiRoot: "https://visitor-us.vee24.com/settings",
  staticRoot: "https://static-us.vee24.com",
  turnAuthRoot: "https://visitor-us.vee24.com/turn",
  screenshotRoot: "https://visitor-us.vee24.com/screenshot",
  engagementApiRoot: "https://visitor-us.vee24.com/engagement",
  linkpreviewRoot: "https://visitor-us.vee24.com/linkpreview",
  agentsRoot: "https://visitor-us.vee24.com/agents",
  assetProxy: "https://static-us.vee24.com/assets",
  crmRoot: "https://visitor-us.vee24.com/crm",
  emailRoot: "https://visitor-us.vee24.com/email",
  fulfillmentRoot: "https://visitor-us.vee24.com/fulfillment",
  appointmentRoot: "https://visitor-us.vee24.com/appointment",
  translationRoot: "https://visitor-us.vee24.com/translate",
  webinarRoot: "https://visitor-us.vee24.com/webinar",
  webinarHub: "https://webinar-us.vee24.com",
  inviteRoot: "https://visitor-us.vee24.com/invite",
  visitorRoot: "https://visitor-us.vee24.com/visitor",
  uploadRoot: "https://visitor-us.vee24.com/upload",
  restApiRoot: "https://api-us.vee24.com",
};
